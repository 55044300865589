import React from 'react'
import {Link} from 'react-router-dom'
import strory1 from '../../images/story/first-date.png'
import strory2 from '../../images/story/officially-dating.png'
import strory3 from '../../images/story/engaged.png'
import './style.css'


const Story = () =>{
    return(
        <div id="story" className="story-area section-padding">
            <div className="container">
                <div className="col-l2">
                    <div className="section-title text-center">
                        <h2>Our Story</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="story clearfix">
                            <div className="story-top"></div>
                            <div className="content-wrapper">
                                <div className="item">
                                    <div className="story-icon">
                                        <span className="flaticon-birds-in-love"></span>
                                    </div>
                                    <div className="story-content">
                                        <div className="image-wrap">
                                            <div className="single-image">
                                                <div>
                                                    <img src={strory1} alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                        <h2>Our First Date</h2>
                                        <span className="date">December 9, 2022</span>
                                        <p>It all began with a proper swipe on the dating app Hinge (not the bride's last name; ask the groom about this story) in the fall of 2022. 
                                            After some text exchange and Mike's attempt at a Thanksgiving dinner invitation, we met on a crisp December 9th evening for dinner and 
                                            wine at Prima Cucina Italiana. Good food, wine, and much laughter were shared on that first evening. 
                                        </p>
              
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="story-icon">
                                        <span className="flaticon-birds-in-love"></span>
                                    </div>
                                    <div className="story-content">
                                        <div className="image-wrap">
                                            <div className="single-image">
                                                <div>
                                                    <img src={strory2} alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                        <h2>Officially Started Dating</h2>
                                        <span className="date">January 1, 2023</span>
                                        <p>We officially started dating after the ball dropped, coincidently continuing the double-digit tradition from our birthdays, May 5th (groom) and 
                                            October 10th (bride). Over the next year of dating, we fell in love through our shared love for trying new food, Larissa dragging Mike to almost 
                                            any event she heard of, and Mike's passion for rollercoasters. This is a tradition that we expanded on this summer with a season pass to all 
                                            of Cedar Fair parks, something Larissa didn't know existed until Mike. The trip to England brought many new experiences and learning about one 
                                            another. We genuinely balance one another out, having many couples and families saying on first meeting us together, they loved what we brought 
                                            out in each other. The banter is alive in this relationship. </p>
 
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="story-icon">
                                        <span className="flaticon-birds-in-love"></span>
                                    </div>
                                    <div className="story-content">
                                        <div className="image-wrap">
                                            <div className="single-image">
                                                <div>
                                                    <img src={strory3} alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                        <h2>Our Engagement</h2>
                                        <span className="date">April 4, 2024</span>
                                        <p>April 4th, though a post may have many thinking it was on the eclipse, Mike popped the question over a beautiful home cooked meal. He always said he 
                                            would be asking in private, and being able to hear his sweet words and cherish those moments together, made it to be a special moment for us. Also, 
                                            sharing in the excitement with our dogs Callie and Rudy was a bonus. Larissa is forever grateful Mike did not ask at a Pens game. </p>
        
                                    </div>
                                </div>
                            </div>
                            <div className="story-bottom"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Story;