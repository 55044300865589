import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import './style.css'
import LocationMap from '../LocationModal/LocationModal'

const Welcome = () =>{
    return(
        <div className="welcome-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="welcome-content">
                            <h2>Welcome to our big day</h2>
                            <p>Hi, party people! We're so excited to invite you to our wedding celebration after our destination wedding on September 9th in the beautiful California Redwoods. Our intimate destination wedding will be attended by our parents. </p>

                            <p>After exchanging vows in the Redwoods, we invite you to celebrate our marriage at Larissa's childhood home on Saturday, September 28th. This meaningful location holds a special place in our hearts, and just a couple of weeks after the ceremony, we'll gather to share in the joy of our union.</p> 

                            <p>Your presence on this day will mean the world as we embark on this new chapter together. Please join us for an evening of love, laughter, and unforgettable memories. </p>

                            <p>Feel free to RSVP and share any songs you'd like to hear at our celebration, the form is below. </p>
                            <div className="btn"><AnchorLink href="#rsvp">RSVP</AnchorLink></div>
                            <LocationMap buttonClass={'location-btn'} />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Welcome;