import React from 'react'
import Sectiontitle from '../section-title'
import strory3 from '../../images/events/reception.png'
import LocationMap from '../LocationModal/LocationModal'

import './style.css'

const Location = () => {
    return (


        <div id="event" className="service-area section-padding">
            <div className="container">
                <Sectiontitle section={'When & Where'} />

                    
                <div className="Ceremony-wrap">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="ceromony-img">
                                <img src={strory3} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="ceromony-content">
                                <h3>Elopement Celebration</h3>
                                <p>Join us for food, drinks and dance as we celebrate our elopement in style!</p>
                                <span><strong>When:</strong> Saturday September 28, 2024 - 5:00 PM till 12:00 AM</span>
                                <span><strong><br/>Where:</strong> 4912 Middletown Road East New Middletown, Ohio 44442</span>
                                <p><br/><strong>Attire:</strong> Dressy - Casual, it's a backyard party so heels are not encouraged. Wear something you feel good in! </p>
                                <p><strong>Timeline of Events:</strong><br/>
                                    - 5 pm - Signature Cocktails 
                                    - 6 pm - Dinner (Italian, Buffet-style)<br/>
                                    - 6:45 pm - Speeches & Toasts<br/>
                                    - 7 pm - First Dance<br/>
                                    - 8 pm - Dancing & Drinks<br/>
                                    - Midnight is Home Time
                                </p>
                                <LocationMap buttonClass={'location-btn'} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Location;